import {Component,OnInit,TemplateRef,} from "@angular/core";
import { DataService } from "src/app/data.service";
import { CreateService } from "../../create.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { MatDialog, MatTabChangeEvent } from "@angular/material";
import { params } from "src/app/pages/params";
import { urls } from "src/app/pages/urls";

@Component({
  selector: "app-create-catalog-grouping",
  templateUrl: "./create-catalog-grouping.component.html",
  styleUrls: ["./create-catalog-grouping.component.scss"],
})
export class CreateCatalogGroupingComponent implements OnInit {
  
  groups: any = [];
  selectedGroup: any = {};
  isGroup: boolean = false;
  attributesList: any = [];
  mappedMSNList: any = [];
  attributeKey: any;
  countryCode: any;
  userEmail: string;
  removeGroupData: any;
  selectedTab: string = "Added Attributes";
  msnKey: any;
  isGroupSelected: boolean = false;
  isActiveMsnList: boolean = false;
  selectedGroupMode:string;
  editGroup:any;
  updatedGroupName:string;
  constructor(private createService: CreateService, public dialog: MatDialog,private service: DataService) {}

  ngOnInit() {
    this.countryCode = this.service.getSelectedCountryCode();
    this.userEmail = this.service.getEmail();
    this.createService.attriUpdate.subscribe((lang) => {
      this.selectedGroup = this.createService.getCreatedGroup();
      this.selectGroup(this.selectedGroup);
    });
    this.createService.createdGroupUpdate.subscribe((lang) => {
      let group = this.createService.getCreatedGroup();
      this.groups = [group];
      this.selectGroup(group);
    });
    this.createService.createdGroupsUpdate.subscribe((lang) => {
      let groupsList = this.createService.getGroups();
      this.groups = groupsList;
      this.selectGroup(this.groups[0]);
    });
    this.createService.msnlistUpdated.subscribe((lang) => {
      this.getMappedMSN();
    });
    this.createService.removeGroupUpdated.subscribe((lang) => {
      this.attributesList = [];
      this.mappedMSNList = [];
      this.selectedGroup = null;
      this.isGroupSelected = false;
    });
  }

  selectGroup(group) {
    this.attributesList = this.mappedMSNList = [];
    this.isGroupSelected = true;
    this.selectedGroup = group;
    this.attributesList = this.selectedGroup["attributeDetails"] ? this.selectedGroup["attributeDetails"] : [];
    this.createService.setSelectedGroup(this.selectedGroup);
    this.selectedGroupMode = this.selectedGroup.catalogGroupDetails.groupedFor
    if (this.groups.length > 1) {
      this.createService.setCreatedGroup1(this.selectedGroup);
    }
    if(this.attributesList.length>0){
      this.createService.attributrList( this.attributesList,this.attributesList.length);
    }else{
      this.createService.attributrList( this.attributesList,0);
    }
    this.getMappedMSN();
  }

  getAllGroups() {
    let url = params.old_prod_supplier + urls.getSearchGroups;
    this.service.callRestful("GET", url, null, null, null, null).subscribe((resp) => {
      if (resp && resp.data !== null && resp.data !== {}) {
          this.groups = resp["data"];
          this.selectGroup(this.groups[0]);
        } else {
          this.service.showMessage("error", resp["statusDescription"]);
        }
        this.service.offSpinner();
      });
  }

  getMappedMSN() {
    let url = params.old_prod_supplier +urls.getMappedMSNByGroup +this.selectedGroup.catalogGroupDetails.groupId;
    this.service.callRestful("GET", url, null, null, null, null).subscribe((resp) => {
      if (resp && resp.data !== null && resp.data !== {}) {
          this.mappedMSNList = resp.data;
        }
        this.service.offSpinner();
      });
  }

  async updateGroup() {
    const url = params.old_prod_supplier + urls.createGroup;
    let body = {
      groupId: this.selectedGroup.catalogGroupDetails.groupId,
      brandId :this.selectedGroup.catalogGroupDetails.brandId,
      attributeId: await this.getAttributesList(this.attributesList),
      categoryId: this.selectedGroup.catalogGroupDetails.categoryId,
      countryCode: this.countryCode,
      userId : this.userEmail,
      priority: 1,
    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe(this.processResult.bind(this));
  }

  processResult(data: any) {
    this.service.offSpinner();
    if (data["status"]) {
      this.service.showMessage("success", data["statusDescription"]);
    } else {
      this.service.showMessage("error", data["statusDescription"]);
    }
  }

  getAttributesList(attributeList) {
    let attrList = attributeList.map((item) => {return item.attributeId});
    return attrList;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.attributesList,event.previousIndex,event.currentIndex);
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTab = tabChangeEvent.tab.textLabel;
  }

  markAsParentMSN(productId) {
    this.service.onSpinner();
    let url =params.old_prod_supplier +urls.updateParentMSN +"?groupId=" +this.selectedGroup.catalogGroupDetails.groupId +"&idProduct=" +productId;
    this.service.callRestful("GET", url, null, null, null, null).subscribe((resp) => {
        if (resp["status"]) {
          this.service.showMessage("success", resp["statusDescription"]);
          this.getMappedMSN();
        } else {
          this.service.offSpinner();
          this.service.showMessage("error", resp["statusDescription"]);
        }
      });
  }

  processUnmappedMSN(productId) {
    let url = params.old_prod_supplier + urls.unmappedMappedMSN;
    let body = {
      groupId: this.selectedGroup.catalogGroupDetails.groupId,
      productId: [productId],
    };
    this.service.callRestful("POST", url, body, null, null, null)
      .subscribe((resp) => {
        if (resp["status"]) {
          this.getMappedMSN();
          this.service.showMessage("success", resp["statusDescription"]);
        } else {
          this.service.showMessage("error", resp["statusDescription"]);
        }
        this.service.offSpinner();
      });
  }

  deleteAttribute(template: TemplateRef<any>, attribute, index) {
    this.attributeKey = attribute.attributeName;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        this.attributesList.splice(index, 1);
        this.createService.attributrList( this.attributesList,this.attributesList.length);
        this.service.showMessage("success", this.attributeKey + " is deleted successfully");
        this.updateGroup();
      }
    });
  }

  deleteMappedMsn(template: TemplateRef<any>, msn, index) {
    this.msnKey = msn;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        this.service.offSpinner();
        this.mappedMSNList.splice(index, 1);
        this.processUnmappedMSN(msn.idProduct);
      }
    });
  }

  removeGroup(template: TemplateRef<any>, group, index) {
    this.removeGroupData = group;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        let url =params.old_prod_supplier +urls.removeGroup +this.removeGroupData.catalogGroupDetails.groupId;
        this.service.callRestful("DELETE", url, null, null, null, null)
          .subscribe((resp) => {
            if (resp["status"]) {
              this.groups.splice(index, 1);
              this.service.showMessage("success", resp["statusDescription"]);
              this.removeGroupData = null;
              this.createService.removeGroup();
            }
            this.service.offSpinner();
          });
      }
    });
  }

  editGroupName(template: TemplateRef<any>,group,i){
    this.editGroup = group;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        let body ={
          "groupId":this.editGroup.catalogGroupDetails.groupId,
          "groupName":this.updatedGroupName,
          "groupedFor":this.editGroup.catalogGroupDetails.groupedFor.toUpperCase(),
          "userId": this.userEmail
        }
          let url = params.old_prod_supplier + urls.editGroupName;
          this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
          if(resp['status']) {
              this.service.showMessage('success', resp['statusDescription']);
              this.editGroup = null;
              this.updatedGroupName = null; 
              this.groups[i].catalogGroupDetails.groupName = resp.data.groupName;
              console.log(this.groups[i])
          }else{
            this.service.showMessage('error',resp.statusDescription)
          }
          this.service.offSpinner();
        });
       }
    });  
   }
}
