/**
 * Created by nitish ranjan on 09/10/18.
 */

export const params = {



   ///////////////////////////////QA UI/////////////////////////////// 

   ui: "https://catalog-dev.moglilabs.com/",
   elastic: "https://api.moglix.com",

   // Old Path for Image 
   // images: "https://img.moglimg.com/",
   auth: "https://authqa.moglilabs.com",
   prod_supp: "https://catalogapi-dev.moglilabs.com",
   ems: "https://emsqa.moglilabs.com",
   old_prod_supplier: "https://platformdata-dev.moglilabs.com",
   old_report_module: "https://platformreports-dev.moglilabs.com",
   online_redirect_path: "https://www.qa.moglilabs.com/",
   api_gateway:"https://catalogsearch-dev.moglilabs.com",
   // New Path for Images
   images: "https://s3.ap-south-1.amazonaws.com/imgqa.moglix.com/",
   //feature Image
   feature_images: "https://imgqa.moglix.com/",
   // SaleOps portal
   saleOps_portal: "https://salesopsapiqa.moglilabs.com",
   spark_module: "https://sparkapi.moglilabs.com",
   // Offer Price config
   price_engine: "https://price-engineqa.moglilabs.com",
   user_management: "https://authqappe.moglilabs.com",
   //user_management_secret_key: "RHVtbWV5d3d3LnN1cHBseTEuY29t",
   user_management_secret_key: "Q2F0YWxvZ2h0dHA6Ly9jYXRhbG9ndWlxYS5tb2dsaXguY29tLw==",
   //audit log
   audit_log: "https://catalogueauditlogapiqa.moglilabs.com",
   audit_log_appID: "J6M1-XEBNcuKOvdqxG9v",
   country_list: "http://platformdata-dev.moglilabs.com",
   subCategories: 'https://platformdata-dev.moglilabs.com',
   analyticsReport: "https://analysis.moglix.com/centralsyncreportapi",
   downloadReport: "https://analysis.moglix.com",
   cpn_msn_suggestion: "https://analysis.moglix.com",
   //google login id
   // google_login_id: "851176149543-kht9pshdrqbfoblpof8kb1bhdjidihvh.apps.googleusercontent.com" 
   google_login_id: "851176149543-9qv29p0ocdspu9vqk289dtuqhkqsb99i.apps.googleusercontent.com",

   uploadDocuments: "https://platformdata-dev.moglilabs.com/cassandraFeed",
   approval_endPoint: "https://catalogservice-qa1.moglilabs.com",
   analytics_endPoint: "https://analytics.moglilabs.com",
   platform_data_feed: "https://platformdatafeedservice-dev.moglilabs.com",
   price_manager: 'https://pricemanagerapiqa.moglilabs.com',
gridMaxSize:30,
//Online Module
  onlineMerchant:"https://online-merchandise.moglilabs.com",
  cisPortal: "https://cis.moglix.com/category-discovery",
  }

