import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, FormControl, Validators, Form } from '@angular/forms';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';

import { DataService } from '../../../../data.service';
import { GlobalStateService } from '../../../../global-state.service';
import { urls } from '../../../urls'
import { params } from '../../../params';
import { HttpParams } from '@angular/common/http';
import { MappingService } from '../../../cpn-mapping.service';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

//import * as moment from " moment ";

@Component({
  selector: 'downloadproduct',
  templateUrl: './downloadproduct.component.html',
  styleUrls: ['./downloadproduct.component.scss']
})
export class DownloadproductComponent implements OnInit {
  inputSearch;
  range: Range = { fromDate: new Date(), toDate: new Date() };
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  _activeFlag: FormGroup;
  _fileTypeFlag: FormGroup;
  _customTag: FormGroup;
  _enterpriseType: FormGroup;
  startDate: any;
  endDate: any;
  msnSearchList: any = [];
  categoryListLevel1: any = [];
  categoryListLevel2: any = [];
  categoryListLevel3: any = [];
  categoryListLevel4: any = [];
  categoryList: any = [];
  selectCategory: any = [];
  selectedCategoryLevel1: any = [];
  selectedCategoryLevel2: any = [];
  selectedCategoryLevel3: any = [];
  selectedCategoryLevel4: any = [];
  categoryCode = 2;
  level2 = false;
  level3 = false;
  level4 = false;
  categoryListLevel1Count: any;
  categoryListLevel2Count: any;
  categoryListLevel3Count: any;
  categoryListLevel4Count: any;
  countryListArray = [];
  countryCode: any;
  countryName: any;
  isdisable: boolean = true;
  brandControl: FormControl = new FormControl();
  _activeFlagOptions = [
    { "id": 1, "name": "TRUE" },
    { "id": 2, "name": "FALSE" }
  ];

  // _customTagOptions = [
  //   { "id": 1, "name": "Select" },
  //   { "id": 2, "name": "Packaging" },
  //   { "id": 3, "name": "Supplier" }
  // ];

  _enterpriseOptions = [
    { "id": 1, "name": "Select" },
    { "id": 2, "name": "GLOBAL" },
    { "id": 3, "name": "JUNK" },
    { "id": 4, "name": "MTO" },
  ];

  _fileTypeOptions = [
    { "id": 1, "name": "Static" },
    { "id": 2, "name": "Attribute" },
    // { "id": 3, "name": "Supplier Price" }
  ];
  pathFile: any;
  checkBoxChecked: boolean = false;
  searchValue: string = '';
  categoryName = '';
  categoryCodes = [];
  searchText$: Subject<string> = new Subject();
  filteredCategories = [];

  constructor(private _state: GlobalStateService, private _dataService: DataService, private fb: FormBuilder) { }

  ngOnInit() {
    this.countryCode = this._dataService.getSelectedCountryCode();
    this.countryName = this._dataService.getSelectedCountryName();
    //  this.getBrandDetails();
    this.getCustomTagList();
    this._activeFlag = this.fb.group({ _activeFlag: ['', Validators.required] });

    let _storeActiveFlag = JSON.parse(localStorage.getItem('_activeFlag')) ? JSON.parse(localStorage.getItem('_activeFlag')) : null;
    if (_storeActiveFlag != null) {
      let __activeStatus = this._activeFlagOptions.find(active => active.id == _storeActiveFlag.id);
      this._activeFlag.patchValue({
        '_activeFlag': __activeStatus
      });
    }
    else {
      let __activeStatus = this._activeFlagOptions.find(active => active.id == 1);
      this._activeFlag.patchValue({
        '_activeFlag': __activeStatus
      });
    }

    this._fileTypeFlag = this.fb.group({ _fileTypeFlag: ['', Validators.required] });
    let _storeAttribuesDownloadFlag = JSON.parse(localStorage.getItem('_attribuesDownloadFlag')) ? JSON.parse(localStorage.getItem('_attribuesDownloadFlag')) : null;
    if (_storeAttribuesDownloadFlag != null) {
      let _fileType = this._fileTypeOptions.find(type => type.id == _storeAttribuesDownloadFlag.id);
      this._fileTypeFlag.patchValue({
        '_fileTypeFlag': _fileType
      });
    }
    else {
      let _fileType = this._fileTypeOptions.find(type => type.id == 1);
      this._fileTypeFlag.patchValue({
        '_fileTypeFlag': _fileType
      });
    }

    this._enterpriseType = this.fb.group({ _enterpriseType: ['', Validators.required] });
    let _enterpriseStatus = this._enterpriseOptions.find(type => type.id == 2);
      this._enterpriseType.patchValue({
        '_enterpriseType': _enterpriseStatus
      });

    this._customTag = this.fb.group({ _customTag: ['', Validators.required] });
    // let _storeCustomTag = JSON.parse(localStorage.getItem('_customTag')) ? JSON.parse(localStorage.getItem('_customTag')) : null;
    // if (_storeCustomTag != null) {
    //   let _CustomStatus = this._customTagOptions.find(custom => custom.id == _storeCustomTag.id);
    //   this._customTag.patchValue({
    //     '_customTag': _customStatus
    //   });
    // }
    // else {


    // let _customStatus = this._customTagOptions.find(custom => custom.id == 1);
    // this._customTag.patchValue({
    //   '_customTag': _customStatus
    // });


    //  }

    this.brandList = JSON.parse(localStorage.getItem('_brandList')) ? JSON.parse(localStorage.getItem('_brandList')) : [];

    const moglixStart = new Date(2015, 0, 1);
    const today = new Date();
    const fromMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const fromMax = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const toMin = new Date(moglixStart.getFullYear(), moglixStart.getMonth(), 1);
    const toMax = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const resetRange = { fromDate: today, toDate: today };


    let _daterRange = JSON.parse(localStorage.getItem('dateRange'))
    if (_daterRange != null) {
      this.startDate = this._dateFormat(new Date(_daterRange.fromDate));
      this.endDate = this._dateFormat(new Date(_daterRange.toDate));
    }
    else {
      _daterRange = { fromDate: moglixStart, toDate: today };
      localStorage.setItem('dateRange', JSON.stringify(this.range));
      this.startDate = this._dateFormat(_daterRange.fromDate);
      this.endDate = this._dateFormat(_daterRange.toDate);
    }

    this.setupPresets();
    this.options = {

      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: new Date(_daterRange.fromDate), toDate: new Date(_daterRange.toDate) },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        // hasBackDrop: false
      },
      // cancelLabel: "Cancel",
      // excludeWeekends:true,
      fromMinMax: { fromDate: fromMin, toDate: fromMax },
      toMinMax: { fromDate: toMin, toDate: toMax }
    };
    this.getCategory("2", 1);
    this.getCountryListing();
    this.onChanges();
    this.searchDebouncer();
  }

  updateSearch(event) {
    this.searchText$.next(event.target.value);
  }

  searchDebouncer() {
    this.searchText$
      .pipe(debounceTime(500), distinctUntilChanged ())
      .subscribe((term: any) => {
        if(term.length > 2) {
          this.getFilteredCategories();
          this.resetCategoryLevel(2);
          this.selectedCategoryLevel1 = [];
          this.selectedCategoryLevel2 = [];
          this.selectedCategoryLevel3 = [];
          this.selectedCategoryLevel4 = [];
        }
      });
  }

  getFilteredCategories() {
    let url = params.old_prod_supplier + urls.OLD.searchCategories;
    let body = {
      searchQuery: this.searchValue,
      'countryCode': this.countryCode,
      'parentBusinessCode':this._dataService.getSelectedParentBusinessCode()

    };
    this._dataService.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp && resp.data !== null && resp.data !== {}) {
        this.categoryCodes = Object.keys(resp.data)
        this.filteredCategories = Object.values(resp.data);
      }
      this._dataService.offSpinner();
    });
  }

  getCountryListing() {
    let URl = params.old_prod_supplier + urls.Country_listing;
    this._dataService.callRestful('GET', URl, null, null, null, null, false).subscribe(data => {
      this.countryListArray = data;
    });
  }

  // onCountryChange(event) {
  //   this.countryCode = event.value;
  //   this.categoryListLevel1 = [];
  //   this.categoryListLevel2 = [];
  //   this.categoryListLevel3 = [];
  //   this.categoryListLevel4 = [];
  //   this.checkBoxChecked = false
  //   if (this.countryCode != null) {
  //     this.isdisable = false;
  //     this.getCategory("2", 1);
  //   }
  // }

  onChanges(): void {
    this.brandControl.valueChanges.subscribe(val => {
      if (val != '' && val.length > 1)
        this.getBrandDetails(val);
    });
  }

  updateRange(range: Range) {
    this.range = range;
    localStorage.setItem('dateRange', JSON.stringify(this.range));
    this.startDate = this._dateFormat(range.fromDate);
    this.endDate = this._dateFormat(range.toDate);
  }

  _dateFormat(date?) {
    var today = date;
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;
    return today;
  }
  // helper function to create initial presets
  setupPresets() {
    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    const moglixStart = new Date(2015, 0, 1);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } },
      { presetLabel: "All", range: { fromDate: moglixStart, toDate: today } }
    ]
  }

  _MS_PER_DAY = 1000 * 60 * 60 * 24;
  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }

  handleClickEvents(cat, i) {
    this.categoryCode = this.categoryCodes[i];
    this.categoryName = cat;
    const index = this.categoryListLevel1.findIndex(each => each.isChecked == true);
    console.log(index);
    if(index > -1) {
      this.checkBoxChecked = false;
      this.categoryListLevel1[index].isChecked = false
    }
  }

  getProductDownload(msnSearch?, supplier?, manufacturer?, activeFlag?, fileTypeFlag?, customTag?, enterpriseType?) {
    let show = true;
    // if(show && msnSearch == "") {
    //   this._dataService.showMessage('downloadMSG', '<b>Request in Under Process estimated time around 10 Mins.</b><br><p>Please be patient and do not close the browser</p>');
    // }
     
    let msn = [];
    msnSearch = msnSearch.trim();
    if (msnSearch != "") {
      msn = msnSearch.split(",").map((msn: string) => msn.trim());
      let msnList = [];
      for (var i in msn) {
        if (msn[i] != "")
          msnList.push(msn[i]);
      }
      msn = [];
      msn = msnList;
    }

    for (let i = 0; i < this.countryListArray.length; i++) {
      if (this._dataService.getSelectedCountryCode() == this.countryListArray[i].countryCode) {
        this.countryName = this.countryListArray[i].countryName
      }
    }

    let categoryCodes = [];
    if (this.selectedCategoryLevel4.length > 0) {
      for (var i in this.selectedCategoryLevel4)
        categoryCodes.push(this.selectedCategoryLevel4[i].categoryCode);
    }
    else if (this.selectedCategoryLevel3.length > 0) {
      for (var i in this.selectedCategoryLevel3)
        categoryCodes.push(this.selectedCategoryLevel3[i].categoryCode);
    }
    else if (this.selectedCategoryLevel2.length > 0) {
      for (var i in this.selectedCategoryLevel2)
        categoryCodes.push(this.selectedCategoryLevel2[i].categoryCode);
    }
    else if (this.selectedCategoryLevel1.length > 0) {
      for (var i in this.selectedCategoryLevel1)
        categoryCodes.push(this.selectedCategoryLevel1[i].categoryCode);
    }else if(this.categoryCode) {
      categoryCodes.push(this.categoryCode);
    }
    // else if () {
    //   category = this.createCategories();
    // }

    var brand = [];
    if (this.brandList.length > 0) {
      for (var i in this.brandList)
        brand.push(this.brandList[i].id);
    }

    let _activeFlag = this._activeFlagOptions.find(active => active.name === activeFlag.value._activeFlag.name)
    localStorage.setItem('_activeFlag', JSON.stringify(_activeFlag));


    let _customTag = this._customTagOptions.find(custom => custom.customTagName === customTag.value._customTag.customTagName);
    let _enterpriseType = this._enterpriseOptions.find(enterprise => enterprise.name === enterpriseType.value._enterpriseType.name);
    localStorage.setItem('_enterpriseType',JSON.stringify(_enterpriseType));

    // localStorage.setItem('_customTag', JSON.stringify(_customTag));

    //  let pricedownloadFlag = fileTypeFlag.value._fileTypeFlag.name === "Supplier Price" ? true : false;
    // let attribuesDownloadFlag = fileTypeFlag.value._fileTypeFlag.name === "Attribute" ? true : false;

    let _attribuesDownloadFlag = this._fileTypeOptions.find(fileType => fileType.name === fileTypeFlag.value._fileTypeFlag.name)
    localStorage.setItem('_attribuesDownloadFlag', JSON.stringify(_attribuesDownloadFlag));
    let attribuesDownloadFlag = fileTypeFlag.value._fileTypeFlag.name === "Attribute" ? true : false;

    // if (msn.length > 1 || this.brandList.length > 1)
    //   attribuesDownloadFlag = false;


    if (this.brandList.length == 0 && categoryCodes.length == 0  && msn.length == 0) {
      attribuesDownloadFlag = false;
    }


    //  //   if (brand.length == 0 && categoryCodes.length == 0 && msn.length == 0 && this.dateDiffInDays(new Date(this.startDate), new Date(this.endDate)) >= 31) {
    //   if (_customTag.customTagName == 'Select' && brand.length == 0 && categoryCodes.length == 0 && msn.length == 0 && this.dateDiffInDays(new Date(this.startDate), new Date(this.endDate)) >= 31) {
    //     //       this._dataService.showMessage("error", "Please select any msn search, brand and category filter");
    //     this._dataService.showMessage("error", "We are restrict to download products more than 31 days");
    //   }     


    if(brand.length === 0 && categoryCodes!= null && categoryCodes.length == 1 && categoryCodes.includes('2')){
      categoryCodes = [];
    }
    if(categoryCodes.length == 0 && brand.length === 0 && msn.length === 0 && _customTag.customTagName.length === 0){
      this._dataService.showMessage("error", "Please select any of the category, brand, custom type or products");
      return 0;
      }
      else if(new Date(this.startDate) > new Date(this.endDate)){
      this._dataService.showMessage("error", "From date should be less than To date");
        return 0;
      }
      else if (_customTag.customTagName == 'Select' && _enterpriseType.name == "Select"&& brand.length == 0 && categoryCodes.length == 0 && msn.length == 0 && this.dateDiffInDays(new Date(this.startDate), new Date(this.endDate)) >= 31) {
      this._dataService.showMessage("error", "We are restrict to download products more than 31 days");
      return 0 ;
      }
      // if( _enterpriseType.name !== "Select"){
        if(categoryCodes.length == 0 && brand.length == 0 && _enterpriseType.name !== "Select"){
        this._dataService.showMessage("error", "Please select a category or brand");
        return 0;
      }
      // }
      
     else {

      let countryCode = this._dataService.getSelectedCountryCode();
      let body = {
        "msnSearch": msn,
        "categoryCodeList": categoryCodes,
        "brandIdList": brand,
        "manufacturerId": null,
        "fromTime": this.startDate,
        "toTime": this.endDate,
        "activeFlag": _activeFlag.name === "TRUE" ? true : false,
        "supplierId": null,
        "country": this.countryName,
        "countryCode": countryCode,
        "pricedownloadFlag": false,
        "attribuesDownloadFlag": attribuesDownloadFlag,
        "customTag": _customTag.customTagName === "Packaging" ? "Packaging" : _customTag.customTagName,
        "requestedBy": this._dataService.getEmail(),
        "enterpriseType": _enterpriseType.name === "Select" ? null : _enterpriseType.name
      }

      let url = params.old_report_module + urls.OLD.getDownloadProductReport;
      this._dataService.callRestful("POST", url, body, null, null, null, true).subscribe(
        (data) => {
          
          if (data['data']['status']) {
            show = false;
            // if(!show && !msn.length){
            //   this._dataService.showMessage('success', 'Downloaded');
            // }
            // if(msn.length) {
              this._dataService.showMessage('downloadMSG', '<b>Request is accepted, once processed will be shared on mail.</b>');
            // }
            this._dataService.offSpinner();
          
            //   this.getResetPage();
            // let fileName = data['data']['filePath'];
            // let body =
            // {
            //   "filePath": fileName
            // }
            // if(msn.length == 0) {
            //   let url = params.old_report_module + fileName;
            //   let self = this;
            //   var link = document.createElement("a");
            //   link.href = url;
            //   link.setAttribute('visibility', 'hidden');
            //   link.download = "fileName" + ".csv";
            //   document.body.appendChild(link);
            //   link.click();
            //   document.body.removeChild(link);
            // }

          } else {
            this._dataService.showMessage("error", data['data']['message']);
          }
        },
        (err) => {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        });
    }
  }

  createCategories() {
    let category: any = [];
    let categories = this.categoryName.split('/');
    categories.forEach(element => {
      category.push({
        categoryCode: null, categoryName: element, productCount: 0
      })
    });
    category[categories.length -1]['categoryCode'] = this.categoryCode;
    return category;
  }

  selectActiveFlag(activeFlag) {
    let _activeFlag = this._activeFlagOptions.find(active => active.name === activeFlag.value._activeFlag.name)
    localStorage.setItem('_activeFlag', JSON.stringify(_activeFlag));
  }

  // selectCustomTag(customTag) {
  //    let _customTag = this._customTagOptions.find(custom => custom.name === customTag.value._customTag.name)
  //   localStorage.setItem('_customTag', JSON.stringify(_customTag));
  // }

  selectFileType(fileTypeFlag) {
    let _attribuesDownloadFlag = this._fileTypeOptions.find(fileType => fileType.name === fileTypeFlag.value._fileTypeFlag.name)
    localStorage.setItem('_attribuesDownloadFlag', JSON.stringify(_attribuesDownloadFlag));
  }

  selectEnterpriseType(enterpriseTypeFlag) {
    let _enterpriseFlag = this._enterpriseOptions.find(enterpriseType => enterpriseType.name === enterpriseTypeFlag.value._enterpriseType.name)
    localStorage.setItem('_enterpriseType', JSON.stringify(_enterpriseFlag));
  }

  alphaNumberOnly(event: any) {
    const pattern = /^[a-zA-Z0-9]+$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(event.target.value + "" + inputChar)) {
      event.preventDefault();
    }
  }

  private downloadCsv(data, fileName) {
    var link = document.createElement("a");
    let blob = new Blob([data], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('visibility', 'hidden');
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getCategory(categoryCode?, level?) {
    this.categoryCode = categoryCode;
    let body = {
      "categoryCode": this.categoryCode,
      "flag": false,
      "countryCode": this.countryCode,
       'parentBusinessCode':this._dataService.getSelectedParentBusinessCode()

    }

    let categoryCodes = [];
    this.resetCategoryLevel(level);
    let url = params.old_prod_supplier + urls.OLD.getCategory;
    this._dataService.callRestful("POST", url, body, null, null, null, true).subscribe(
      (data) => {
       
        if (data['status']) {
          this._dataService.offSpinner();
          this.categoryList = [];
          this.categoryList = data['categoryList'];
          if (this.categoryList.length > 0) {

            for (var i in this.categoryList) {
              var categoryCode = this.categoryList[i]['categoryCode'];
              var categoryName = this.categoryList[i]['categoryName'];
              var productCount = this.categoryList[i]['productCount'];

              let id;
              if (level === 1)
                id = "level1" + i;
              else if (level === 2)
                id = "level2" + i;
              else if (level === 3)
                id = "level3" + i;
              else if (level === 4)
                id = "level4" + i;

              let object = {
                "id": id,
                "categoryCode": categoryCode,
                "categoryName": categoryName,
                "productCount": productCount,
                "isChecked": false
              }
              if (level === 1)
                this.categoryListLevel1.push(object);
              else if (level === 2)
                this.categoryListLevel2.push(object);
              else if (level === 3)
                this.categoryListLevel3.push(object);
              else if (level === 4)
                this.categoryListLevel4.push(object);

              categoryCodes.push(categoryCode);
            }
          }

          let categoryCodeRequest = {
            "categoryCodeList": categoryCodes
          }
          if (null != categoryCodes) {
            let url = params.old_report_module + urls.OLD.getProductCountByCode;
            this._dataService.callRestful("POST", url, categoryCodeRequest, null, null, null, false).subscribe(
              (data) => {
                //this._dataService.offSpinner();
                if (data['status']) {
                  let categoryProductCountList = data['categoryList'];
                  if (categoryProductCountList.length > 0) {
                    for (var i in categoryProductCountList) {
                      if (level === 1) {
                        for (var j in this.categoryListLevel1) {
                          if (this.categoryListLevel1[j].categoryCode === categoryProductCountList[i]['categoryCode']) {
                            this.categoryListLevel1[j].productCount = categoryProductCountList[i]['productCount'];
                            break;
                          }
                        }
                      }
                      else if (level === 2) {
                        for (var j in this.categoryListLevel2) {
                          if (this.categoryListLevel2[j].categoryCode === categoryProductCountList[i]['categoryCode']) {
                            this.categoryListLevel2[j].productCount = categoryProductCountList[i]['productCount'];
                            break;
                          }
                        }
                      }
                      else if (level === 3) {
                        for (var j in this.categoryListLevel3) {
                          if (this.categoryListLevel3[j].categoryCode === categoryProductCountList[i]['categoryCode']) {
                            this.categoryListLevel3[j].productCount = categoryProductCountList[i]['productCount'];
                            break;
                          }
                        }
                      }
                      else if (level === 4) {
                        {
                          for (var j in this.categoryListLevel4) {
                            if (this.categoryListLevel4[j].categoryCode === categoryProductCountList[i]['categoryCode']) {
                              this.categoryListLevel4[j].productCount = categoryProductCountList[i]['productCount'];
                              break;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              });
          }
        }
        else {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }
  resetCategoryLevel(level) {

    if (level === 2) {
      this.categoryListLevel2 = [];
      this.categoryListLevel3 = [];
      this.categoryListLevel4 = [];
    }
    else if (level === 3) {
      this.categoryListLevel3 = [];
      this.categoryListLevel4 = [];
    }
    else if (level === 4) {
      this.categoryListLevel4 = [];
    }
  }

  /* categoryLevel1Select(e, index, category) {
     let k = this.categoryListLevel1.findIndex(x => x.id == category.id);
     this.categoryListLevel1[k].isChecked = e.checked;
     if (e && e.checked) {
       this.selectedCategoryLevel1.push(category);
     } else {
       let i = this.selectedCategoryLevel1.findIndex(x => x.id == category.id);
       if (i >= 0) {
         this.selectedCategoryLevel1.splice(i, 1);
       }
     }
     if (this.selectedCategoryLevel1.length == 1) {
       this.getCategory(this.selectedCategoryLevel1[0].categoryCode, 2);
     }
     else {
       this.selectedCategoryLevel2 = [];
       this.selectedCategoryLevel3 = [];
       this.selectedCategoryLevel4 = [];
     }
     this.getCategoryLevel(2);
   }
 */

  categoryLevel1Select(e, index, category) {
    this.categoryCode = null;
    this.categoryName = '';
    this.filteredCategories = [];
    this.searchValue = '';
    let k = this.categoryListLevel1.findIndex(x => x.id == category.id);
    this.categoryListLevel1[k].isChecked = e.checked;
    if (e && e.checked) {
      this.selectedCategoryLevel1.push(category);
      this.checkBoxChecked = true;
    } else {
      let i = this.selectedCategoryLevel1.findIndex(x => x.id == category.id);
      if (i >= 0) {
        this.selectedCategoryLevel1.splice(i, 1);
      }
      this.checkBoxChecked = false;
    }
    if (this.selectedCategoryLevel1.length == 1) {
      this.getCategory(this.selectedCategoryLevel1[0].categoryCode, 2);
    }
    else {
      this.selectedCategoryLevel2 = [];
      this.selectedCategoryLevel3 = [];
      this.selectedCategoryLevel4 = [];
    }
    this.getCategoryLevel(2);
  }


  categoryLevel2Select(e, index, category) {
    let k = this.categoryListLevel2.findIndex(x => x.id == category.id);
    this.categoryListLevel2[k].isChecked = e.checked;
    if (e && e.checked) {
      this.selectedCategoryLevel2.push(category);
    } else {
      let i = this.selectedCategoryLevel2.findIndex(x => x.id == category.id);
      if (i >= 0) {
        this.selectedCategoryLevel2.splice(i, 1);
      }
    }

    if (this.selectedCategoryLevel2.length == 1) {
      this.getCategory(this.selectedCategoryLevel2[0].categoryCode, 3);
    }
    else {
      this.selectedCategoryLevel3 = [];
      this.selectedCategoryLevel4 = [];
    }
    this.getCategoryLevel(3);
  }

  categoryLevel3Select(e, index, category) {
    let k = this.categoryListLevel3.findIndex(x => x.id == category.id);
    this.categoryListLevel3[k].isChecked = e.checked;
    if (e && e.checked) {
      this.selectedCategoryLevel3.push(category);
    } else {
      let i = this.selectedCategoryLevel3.findIndex(x => x.id == category.id);
      if (i >= 0) {
        this.selectedCategoryLevel3.splice(i, 1);
        this.selectCategory.splice(i, 1);
      }
    }

    if (this.selectedCategoryLevel3.length == 1) {
      this.getCategory(this.selectedCategoryLevel3[0].categoryCode, 4);
    }
    else {
      this.selectedCategoryLevel4 = [];
    }
    this.getCategoryLevel(4);
  }

  categoryLevel4Select(e, index, category) {
    let k = this.categoryListLevel4.findIndex(x => x.id == category.id);
    this.categoryListLevel4[k].isChecked = e.checked;
    if (e && e.checked) {
      this.selectedCategoryLevel4.push(category);
    } else {
      let i = this.selectedCategoryLevel4.findIndex(x => x.id == category.id);
      if (i >= 0) {
        this.selectedCategoryLevel4.splice(i, 1);
        this.selectCategory.splice(i, 1);
      }
    }
    //   this.getCategoryLevel(4);
  }

  getCategoryLevel(level) {
    if (level === 2 && this.selectedCategoryLevel1.length === 1) {
      this.level2 = true;
    }
    else {
      this.level2 = false;
      this.level3 = false;
      this.level4 = false;
    }
    if (level === 3 && this.selectedCategoryLevel2.length === 1) {
      this.level2 = true;
      this.level3 = true;
    }
    else {
      if (level === 3) {
        this.level2 = true;
        this.level3 = false;
        this.level4 = false;
      }
    }
    if (level === 4 && this.selectedCategoryLevel3.length === 1) {
      this.level2 = true;
      this.level3 = true;
      this.level4 = true;
    }
    else {
      if (level === 4) {
        this.level2 = true;
        this.level3 = true;
        this.level4 = false;
      }
    }

  }
  /* brandList: any = [];
   selectBranch(brand) {
     for (var i in this.brandDetails) {
       let flag = true;
       if (this.brandDetails[i].name === brand) {
         if (this.brandList.length > 0) {
           for (var j in this.brandList) {
             if (this.brandList[j].name === brand)
               flag = false;
           }
         }
         if (flag)
           this.brandList.push(this.brandDetails[i]);
       } else {
         this._dataService.showMessage("error", "Please select brand from drop down");
       }
     }
     localStorage.setItem('_brandList', JSON.stringify(this.brandList));
     (<HTMLInputElement>document.getElementById('_brandId')).value = "";
   }
 */

  brandList: any = [];
  selectBranch(brand) {
    for (var i in this.brandDetails) {
      let flag = true;
      if (this.brandDetails[i].name === brand) {
        if (this.brandList.length > 0) {
          for (var j in this.brandList) {
            if (this.brandList[j].name === brand)
              flag = false;
          }
        }
        if (flag) {
          if (this.brandList.length < 2){
            this.brandList.push(this.brandDetails[i]);
          }else
            this._dataService.showMessage("error", "We are restrict to download products upto 2 brands");
        }
      }
    }
    if (this.brandList.length === 0) {
      this._dataService.showMessage("error", "Please select brand from drop down");
    }
    localStorage.setItem('_brandList', JSON.stringify(this.brandList));
    (<HTMLInputElement>document.getElementById('_brandId')).value = "";
  }

  removeBrand(brand) {
    let i = this.brandList.findIndex(x => x.id == brand.id);
    if (i >= 0) {
      this.brandList.splice(i, 1);
    }
    localStorage.setItem('_brandList', JSON.stringify(this.brandList));
  }
  brandDetails: any = [];
  getBrandDetails(brandName?) {
    if (null != brandName) {
      const encodedString = encodeURIComponent(brandName);
      let url = params.old_prod_supplier + urls.OLD.getBrandList;
      let parameters = new HttpParams().set('name', encodedString).set('status', 'true').set('countryCode', this.countryCode);
      //    let url = params.old_prod_supplier + urls.OLD.getBrandDetails + "?name=" + brandName;
      this._dataService.callRestful("GET", url, null, null, null, parameters, false).subscribe(
        (data) => {
          this._dataService.offSpinner();
          if (data['status']) {
            this.brandDetails = data['responseList'];
          } else {
            this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
          }
        },
        (err) => {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        });
    }
  }

  _customTagOptions: any = [];
  customTagList: any = [];
  getCustomTagList() {
    let url = params.old_prod_supplier + urls.OLD.getCustomTagFlagging;
    //   let parameters = new HttpParams().set('name', brandName).set('status', 'true');
    //    let url = params.old_prod_supplier + urls.OLD.getBrandDetails + "?name=" + brandName;
    this._dataService.callRestful("GET_MSN", url, null, null, null, null, false).subscribe(
      (data) => {
        this._dataService.offSpinner();
        if (data['status']) {
          let customObject = {
            "id": "1",
            "customTagName": "Select"
          }
          this.customTagList.push(customObject);

          let customPankagingObject = {
            "id": "2",
            "customTagName": "Packaging"
          }
          this.customTagList.push(customPankagingObject);

          if (null != data['customTagDetailsList']) {
            for (var j in data['customTagDetailsList']) {
              this.customTagList.push(data['customTagDetailsList'][j]);
            }
          }
          //  this.customTagList = data['customTagDetailsList'];
          this._customTagOptions = this.customTagList;
          let _customStatus = this._customTagOptions.find(custom => custom.id == 1);
          this._customTag.patchValue({
            '_customTag': _customStatus
          });
        } else {
          this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
        }
      },
      (err) => {
        this._dataService.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }



  getResetPage() {
    this.categoryListLevel1 = [];
    this.categoryListLevel2 = [];
    this.categoryListLevel3 = [];
    this.categoryListLevel4 = [];

    this.getCategory("2", 1);
    this.resetCategoryLevel(2);

    (<HTMLInputElement>document.getElementById('_search')).value = "";
    this.brandList = [];
    localStorage.setItem('_brandList', JSON.stringify(this.brandList));

    let __activeStatus = this._activeFlagOptions.find(active => active.id == 1);
    this._activeFlag.patchValue({
      '_activeFlag': __activeStatus
    });

    let _fileType = this._fileTypeOptions.find(type => type.id == 1);
    this._fileTypeFlag.patchValue({
      '_fileTypeFlag': _fileType
    });

    this.updateRange({ fromDate: new Date(2015, 0, 1), toDate: new Date() });
  }

  //   disableFlag: boolean = false;
  //   disabledField(searchMSN?)
  // {
  //   if(searchMSN === '')
  //     this.disableFlag = false;
  //   else
  //     this.disableFlag = true;
  // }
}



