/**
 * Created by nitish ranjan on 09/10/18.
 */


export const urls = {
    // to get unmapped/mapped cpn
    getCpn: '/mapper/cpn/get',
    // to get msn from cassandra (without pojo)
    getMsn: '/mapper/msn/get/',
    // assign msn
    assignMsn: '/mapper/msn/assign',
    // keep the same msn(diaog box)
    keepAssignMsn: '/mapper/keep/msn/assign',
    // no use (depricated)
    getElasticMsnList: '/search',
    // undo mapping
    undoMapping: '/mapper/mapping/undo',
    // authticate user
    loginUser: '/auth/login',
    // authticate user
    catalogUserLogin: '/catalog/user/login',
    // fetch cpn by id
    fetchCpnById: '/mapper/fetchCpn',
    // fetch Msn from elastic search
    fetchElaticMsn: '/mapper/msn/fetch',
    // fetch child msns from elastic search
    fetchElaticChildMsn: '/mapper/fetchChildMsn',
    // fetch child msn fo cis group from elastic search
    fetchElasticCisChildMsn : '/mapper/fetchCisChildMsn/',
    // total msn count
    totalMsnCount: '/cassandraFeed/catalog/msnCount?country=',
    // to get msn from cassandra (with pojo)
    getPojoMsn: '/mapper/fetchMsn',
    // send chat to chat database
    sendChat: '/chat/insert',
    // Read chat
    getChat: '/chat/get',
    // Read Update chat
    getUpdateChat: '/chat/update',
    // total unread chat count by cpn Id
    chatCount: '/chat/count',
    // read chat
    readChat: '/chat/read',
    // unread cpn item count only
    unreadItemsCount: '/chat/unreadItemsCount',
    // unread cpn item count detail
    cpnItemCount: '/chat/cpnItemChatList',
    // unread cpn item count detail
    unreadItemCount: '/chat/cpnItemChatList/unread',
    // fetch cpn mapping against msn
    fetchCpnAgainstMsn: '/mapper/cpnListAgainstMsn',
    // unread cpn item count by item Id
    unreadCpnItemCountByItemId: '/chat/unreadCpnItemCountById',
    // delete cpn mapping against msn
    deleteCpnMapping: '/mapper/deleteCpnAgainstMsnResponse',
    // cpn details with mapping(edit mapping page)
    cpnMappingDetail: '/mapper/cpnDetailWithMapping',
    // team detail
    team: '/api/order/getTeams',
    // unread cpn item count detail
    cpnItemsCount: '/chat/filter',
    // generate report of mapped cpn
    generateReport: '/report/saveReport',
    // download repport with file name
    // downloadReport: '/report/downloadReport',
    //download report by changing analytic URl
    downloadReport: '/tmp',
    //suggestion
    cpn_msn_suggestion: '/product_suggestion/search',
    // item ref order demand check
    getOrderItemDemandStatus: '/api/order/getOrderItemDemandStatus',
    // item ref order demand status in
    getOrderItemStatusFromSaleOps: '/mapper/saleOps/itemStatus/',
    // attribute delete operation by spark module
    getSparkFileDownload: '/sparkSQL/getDownloadFile',
    // analytics suggestion
    getAnalyticsSuggestion: '/mapper/analytics/msn/suggestion/',
    // save future cpn msn mapping
    saveCpnMsnMapping: '/mapper/futureCpnMsnMapping/save',
    // get future cpn msn mapping by product cpn & product id
    getFutureCpnMsnMappingByMappingKey: '/mapper/get/futureCpnMsnMappingByMappingKey',
    // get brand by id
    getBrandById: '/cassandraFeed/brand/getById',
    // create brand
    createBrand: '/cassandraFeed/brand/create',
    // image upload brand
    brandImageUpload: '/cassandraFeed/brand/uploadBrandImage',
    // update brand
    updateBrand: '/cassandraFeed/brand/update',
    // view brand
    viewBrand: '/search/brands',
    //'/cassandraFeed/brand/view',
    // Display brand document list
    brandDocumentListingByPagination: '/cassandraFeed/brand/document/list',
    // brand document download API
    brandDocumentDownload: '/cassandraFeed/brand/document/download',
    // save brand document in update senario
    saveBrandDocument: '/cassandraFeed/brand/document/save',
    // Delete files from s3 server in AWS
    deleteBrandDoumentFromS3Server: '/cassandraFeed/brand/document/delete',
    // uplod brand document in s3 server in AWS
    uploadBrandDoumentInS3Server: '/cassandraFeed/brand/document/upload',
    // latest document toggle
    latestBrandDocumentStatus: '/cassandraFeed/brand/document/latest/status/toggle',
    analyticsReport: '/catalog/cpn_msn_mapping/report/download',
    futureReportDownload: '/catalog/future_mapping/report/download',
    notifyDocumentStatus :'/cassandraFeed/brand/notify/documents/status',

    standardProductDownload:'/cassandraReports/productReports/download/brands/standard-products?brandId=',

    ////////////////////////////////////////////////////////////////
    OLD: {
        //upload cpn list 
        uploadCpnList : '/cassandraReports/cpn/download/msn',
        //upload msn list
        uploadMsnList : '/cassandraReports/cpn/download/cpn',
        // upload new bulk product
        uploadBulkNewProducts: '/cassandraFeed/bulkUpload/uploadNewProducts',
        // update bulk product
        updateBulkProducts: '/cassandraFeed/bulkUpload/updateProductsData',
        // active status update
        updateActiveProductStatus: '/cassandraFeed/bulkUpload/activeStatusUpdate',
        // verify the product
        verifyProduct: '/cassandraFeed/upload/getDetails',
        //  delete upload file
        deleteUploadedFile: '/cassandraFeed/upload/deleteFile',
        // verify view-succeeded
        verifyViewSucceeded: '/cassandraFeed/product/getUploadedRawProductsByUploadId',
        // verify view-Warning
        verifyViewWarning: '/cassandraFeed/product/getWarningRawProductsByUploadId',

        // failure product case
        verifyFailure: '/cassandraFeed/product/getFailedRawProductsByUploadId',
        // verifyProduct
        verify: '/cassandraFeed/product/verifyProducts',
        // Discard Product
        discard: '/cassandraFeed/product/deleteUnverifiedProducts',
        // get Product Details
        getRawProductById: '/cassandraFeed/product/getRawProductById',
        // create raw MSN
        createRawMsn: '/cassandraFeed/catalog/createRawProduct',
        // create MSN
        createMsn: '/cassandraFeed/catalog/createProduct',
        // update raw product
        updateRawMsn: '/cassandraFeed/catalog/updateRawProduct',
        // update product
        updateMsn: '/cassandraFeed/catalog/updateProduct',
        // get category list
        getCategoryList: '/cassandraFeed/catalog/category/list',
        //universal category search
        searchCategories: '/cassandraFeed/catalogCategory/categorySearch',
        // get attribute list
        getAttributList: '/cassandraFeed/catalog/categoryAttributeMap',
        // get MSN details
        //   getMsnDetails: "/cassandraFeed/catalog/rawProduct",
        // get MSN details
        getRawProductFromProduct: '/cassandraFeed/catalog/rawProductFromProduct',
        // get brand list
        getBrandList: '/cassandraFeed/catalog/search/brandByName',
        // get manufacture list
        getManufactureList: '/cassandraFeed/catalog/search/manufactureByName',
        // get category list
        getCategory: '/cassandraFeed/catalog/category/list',
        // get download product data report
        getDownloadProductReport: '/cassandraReports/productReports/download',
        getProductDumpDownloadRequest: '/cassandraReports/productReports/dump/download/request',
        // get All brand
        //  getBrandDetails: "/cassandraFeed/catalog/search/brandByName",
        // get product count by codes
        getProductCountByCode: '/cassandraReports/productReports/category/count',
        // get Download file
        getDownloadFile: '/cassandraReports/productReports/getDownloadFile',
        // get Product File
        getProductFile: '/cassandraReports/productReports/getProductFile',
        // create brand
        createBrand: '/cassandraFeed/catalog/createBrand',
        // create Category
        createCategory: '/cassandraFeed/catalogCategory/add',
        // get brand by name
        getBrand: '/cassandraFeed/catalog/getBrand',
        // get brand by id
        getBrandById: '/cassandraFeed/catalog/getBrandById',
        // update brand
        updateBrand: '/cassandraFeed/catalog/updateBrand',
        // update category
        updateCategory: '/cassandraFeed/catalogCategory/update',
        // image upload product
        productImageUpload: '/cassandraFeed/catalog/uploadProductImage',
        //feature image
        featureImage: '/cassandraFeed/catalog/upload/product/feature-image',
        // get category details
        getCatDetails: '/cassandraFeed/category/getByCode',
        // image upload category
        categoryImageUpload: '/cassandraFeed/catalog/uploadCategoryImage',
        // get Download file
        getDeleteDownloadFile: '/cassandraReports/productReports/getDeleteFile',
        // get Attribute List
        getAttributeList: '/cassandraFeed/attribute/get?countryCode=',
        // Create Attribute
        createAttribute: '/cassandraFeed/attribute/create',
        // fetch Template
        getFetchTemplate: '/cassandraFeed/template/fetchTemplateByCategoryCode',
        // add Template
        addTemplate: '/cassandraFeed/template/add',
        // download csv template
        downloadCsvTemplate: '/cassandraFeed/template/downloadCatalogTemplate',
        // delete attribute
        deleteAttribute: '/cassandraFeed/attribute/delete',
        // Delete Category Attribute
        deleteCategoryAttribute: '/cassandraFeed/template/delete',
        // Template File Download
        templateFileDownload: '/cassandraFeed/template/getDownloadFile',
        // upload supplier csv file
        uploadSupplierFile: '/cassandraFeed/supplier/product/upload',
        // mark out of stock
        markOutOfStock: '/cassandraFeed/supplier/product/uploadStockStatus',
        // update supplier-product mapping
        updateSupplierProductMapping: '/cassandraFeed/supplier/product/update',
        // update supplier-product demapping
        updateSupplierProductDemapping: '/cassandraFeed/utility/demapSupplierMsn',
        // Product Active/ Inactive from UI
        updateActiveProductStatusByUI: '/cassandraFeed/catalog/updateProductStatus',
        // marketing parameter
        marketingParameter: '/cassandraFeed/bulkUpload/uploadProductsAnalyticsParam',
        // add bulk Template
        addBulkTemplate: '/cassandraFeed/template/bulkUpload/add',
        //universal value delete
        deleteValue: '/cassandraFeed/template/delete/attributeValues',
        // ofline custom tag list
        getCustomTagFlagging: '/cassandraFeed/customtags/offline/getAll',
        // Supplier OOS stock download
        getSupplierOOSDownload: '/cassandraFeed/supplier/product/mappedSuppliersInfo',
        // fetch universal values for suggestion
        getUniversalAttributes: '/cassandraFeed/template/fetchUniversalAttributeValues',
        // Cancellation for rawProduct
        cancelRawProduct: '/cassandraFeed/catalog/cancelRawProduct',
        //getBusinessDetails
        getBusinessDetails:'/cassandraFeed/businessUnitsDetails',
        //businessViewUpload
        uploadBusinessView: '/cassandraFeed/product/view/update',


        //grid products
        gridCategories: "/cassandraFeed/grid/categories",
        gridProducts:"/cassandraFeed/grid/products",
        gridPproductsRemove:"/cassandraFeed/grid/products/remove",
        searchProducts:"/search/category/products",
        gridProductsAdd:"/cassandraFeed/grid/products/add",
        categoryProductsRemove:"/cassandraFeed/grid/category/products/remove",
        
      

    },

    Online: {
        // Offer price
        offerPrice: '/priceengine/update/offerPrice',
        getPaginatedData:'/merchant/getPaginatedData',
        dataByMultiPart:'/merchant/prepareDataByMultipart',
        getDetailsInfo:'/merchant/getDetailInfo'
    },
    DigiMRO:{
        offerPrice:'/bulkprice/upload'
    },

    UserManagement: {
        accessModuleList: "/usermanagement/getUserConfiguredApps"
    },
    auditLog: {
        nonPrice: "/auditlogapi/catalog/auditlog/product",
        price: "/auditlogapi/catalog/auditlog/product-price",
        CPNMSN: "/auditlogapi/catalog/auditlog/cpn-msn",
        brandDetails: "/auditlogapi/catalog/audit/brandLogs",
        Category: "/auditlogapi/category/auditlog/category",
        spUpdate: "/priceengine/update/pricingHistory"
    },
    Country_listing: "/cassandraFeed/catalog/country/list",
    productSubCategory: "/cassandraFeed/catalog/business/verticals",
    getBrandTag: "/cassandraFeed/catalog/brand-tag/list",

    uploadDocuments: "/cassandraFeed/product/document/upload",
    downloadDocumentLink: "/product/document/download",
    //fetch UOM LIST
    fetchUOMList: "/cassandraFeed/catalog/uom/list",
    //download document on Msn
    DownloadDocuments: '/cassandraFeed/product/document/download',


    dynamicField: {
        dataType: '/cassandraFeed/catalog/dynamicfield/type',
        saveDynamicField: '/cassandraFeed/catalog/add/dynamicfield',
        updateDynamicField: '/cassandraFeed/catalog/update/dynamicfield',
        deleteDynamicField: '/cassandraFeed/catalog/delete/dynamicfield',
        listingFields: "/cassandraFeed/catalog/dynamicfields"
    },
    // fetchCountry: "/cassandraFeed/catalog/country/list"
    //////// starting of APIs for Supplier Approval /////
    //fetch products for the supplier approval or rejection
    fetchApprovalProducts: '/api/price/requests',
    productApproval: '/api/price/request/approve',
    productReject: '/api/price/request/reject',
    supplierList: '/api/price/suppliers',
    categories: '/api/filter/category/firstlevel',
    retailStock: '/api/stock/retail/status',
    commercialUpload: '/supplierdeal/bulk/update',
    //////// End of APIs for Supplier Approval /////
    fetchCategoryListForMsn: '/mapper/parentHierarchy',
    fetchGlobalCategories: '/cassandraFeed/getGlobalCategoryHierarchy',
    getPdfNames: '//pdf_extraction/get_pdf_names',
    lpaUpload: '//pdf_extraction/file_upload',
    //End of lpa API's

    // CPN MSN VERIFY /////
    updateFile: '/mapper/bulk/msn/assign',
    fileList: '/mapper/getDetails',
    failureData: '/mapper/getFailedRawCpnDetailByUploadId',
    successData: '/mapper/getUpdatedRawCpnDetailByUploadId',
    verifyCPN: '/mapper/verifyCpnMsnMapping',
    deleteFile: '/mapper/deleteFile',
    // Future Mapping //
    futureMapping: '/msn-mapping/future/bulk/map',
    getFiles: '/msn-mapping/future/bulk/files/data',
    getFileData: '/msn-mapping/future/bulk/files/history',
    verfiyFutureMapping: '/msn-mapping/future/bulk/verify',

    // VERIFY ASYNC //
    bulkProductsUpdate: '/asyncProduct/updateProductsData',
    fetchAsyncData: '/asyncProduct/getDetails',
    fetchProductCount: '/cassandraFeed/category/getAllProductsCountByCategory/',

    // Download staic field data
    downloadStaticData: '/cassandraReports/productReports/downloadDFcsv',

    //auto msn & map
    suggest_product: '/cpn-msn/suggest/product', //'/cpnmsn/suggest/product',
    create_map: '/cpn-msn/create-map/product', //'/cpnmsn/creat/mapproduct'

    //Grouping
    createGroup: '/cassandraFeed/group/add',
    updateGroup: '/cassandraFeed/group/update',
    getSearchGroups: '/cassandraFeed/group/get',
    uploadMSNBulk: '/cassandraFeed/group/map/bulkProducts',
    uploadGroupMSN: "/cassandraFeed/group/map/bulkProducts/groups?userId=",
    getMappedMSNByGroup: '/cassandraFeed/group/get/products?groupId=',
    removeGroup: '/cassandraFeed/group/delete?groupId=',
    editGroupName:'/cassandraFeed/group/edit',
    unmappedMappedMSN: '/cassandraFeed/group/unmap/products',
    updateParentMSN: '/cassandraFeed/group/update/parent',
    bulkUploadGroup: '/cassandraFeed/group/bulk/create',
    bulkUpdateGroup: '/cassandraFeed/group/bulk/update',
    getAllGroups: '/cassandraFeed/group/all',
    getMSNList: '/cassandraFeed/group/getMSN',
    MapMSNList: '/cassandraFeed/group/map/products',
    downloadGroups: '/cassandraFeed/group/download',

    //upload categoty document
    uploadDocumentCategory: "/cassandraFeed/catalogCategory/document/upload",

    //Customizable attribute universe
    getCustomizableAttribute: "/cassandraFeed/template/customizable?categoryCode=",

    businessUnits: {
        getCatalogBusinessUnits:
            "/cassandraFeed/businessUnits"
    }


}
