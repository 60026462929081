import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { GlobalStateService } from 'src/app/global-state.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { MappingService } from 'src/app/pages/cpn-mapping.service';
import { params } from 'src/app/pages/params';
import { urls } from 'src/app/pages/urls';
import { Utility } from 'src/app/pages/utility';
import { CreateService } from '../../create.service';
import { MatDialog } from '@angular/material';
import { MatTableDataSource,MatRadioButton,MatRadioGroup } from "@angular/material";

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {

  
  @ViewChild('groupname') groupname: ElementRef;   
  searchBrand$: Subject<string> = new Subject();
  searchCategory$: Subject<string> = new Subject();
  searchCategory: string = '';
  searchBrand: string = '';
  filteredCategories = [];
  categoryCodes = [];
  countryCode: number;
  brandCode: number;
  brandList: any = [];
  categoryCode: any;
  group: String = '';
  userEmail: string;
  file: any;
  fileName: any;
  popupData: any = [];
  @ViewChild('confirmDialog1') confirmDialog1: TemplateRef<any>;
  displayedColumns: string[] = [
    "groupName",
    "categoryCode",
    "brandName",
    "attributes",
    "failureReason"
  ];
  groupingFor:string;
  public dataSource = new MatTableDataSource(this.popupData);

  constructor(private createService : CreateService, public dialog: MatDialog, private _state: GlobalStateService, private service: DataService, private fb: FormBuilder, public mappingService: MappingService) {
    this.groupingFor='';
  }

  ngOnInit() { 
    this.countryCode = this.service.getSelectedCountryCode();
    this.userEmail = this.service.getEmail();
    this.searchDebouncer();
    this.onBrandChanges();
  }

  searchDebouncer() {
    this.searchCategory$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((term: any) => {
        if(term.length > 2) {this.getFilteredCategories();}
      });
  }

  updateSearch(event) {
    this.searchCategory$.next(event.target.value);
  }

  getFilteredCategories() {
    let url = params.old_prod_supplier + urls.OLD.searchCategories;
    let body = {
      searchQuery: this.searchCategory,
      'countryCode': this.countryCode,
      'parentBusinessCode':this.service.getSelectedParentBusinessCode()

    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp && resp.data !== null && resp.data !== {}) {
        this.categoryCodes = Object.keys(resp.data)
        this.filteredCategories = Object.values(resp.data);
      }
      this.service.offSpinner();
    });
  }
  resetFilteredcategory(){
    this.filteredCategories = this.categoryCodes = []; 
    this.categoryCode = null;
  }

  selectedCategory(category, i){
    this.searchCategory = category;
    this.categoryCode = this.categoryCodes[i]; 
    this.filteredCategories = this.categoryCodes = [] 
  }

  updateBrandSearch(event) {
    this.searchBrand$.next(event.target.value);
  }

  onBrandChanges(): void {
    this.searchBrand$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val: any) => {
      if (val && val.length > 1) {
        let url = params.old_prod_supplier + urls.OLD.getBrandList;
        let parameters = new HttpParams().set('name', val).set('status', 'false').set('countryCode', this.countryCode.toString());
        this.service.callRestful("GET", url, null, null, null, parameters, false).subscribe(this.processBrandList.bind(this));
      }
    });
  }

  processBrandList(data: any) {
    this.service.offSpinner();
    this.brandList = data.responseList;
  }

  resetFilteredBrand(){
    this.brandList = []; 
    this.brandCode =null;
  }

  selectedBrand(brandName, brandId){
    this.searchBrand = brandName;
    this.brandCode = brandId; 
    this.brandList = [];
  }

  createGroup(){
    this.service.onSpinner();
    const url = params.old_prod_supplier + urls.createGroup;
    let body = {
      "groupName" : this.group,
      "brandId" :this.brandCode,
      "categoryId" :this.categoryCode,
      "attributeId" : [],
      "userId" :this.userEmail,
      "countryCode" : this.countryCode,
      "priority" : 1,
      "groupedFor": this.groupingFor
    }
    if(!this.groupingFor){
      this.service.showMessage('error', 'Please select Grouping Mode');
      this.service.offSpinner();
    }
    else{

    this.service.callRestful('POST', url, body, null, null, null).subscribe(data=> {
      if (data['status']) {
        this.resetForm();
        let body = {
          "brandId": data.data.brandId,
          "groupName": data.data.groupName,
          "categoryCode": data.data.categoryId,
          "groupedFor": data.data.groupedFor.toUpperCase()
         }
        this.service.showMessage('success', data['statusDescription']);
        let url = params.old_prod_supplier + urls.getSearchGroups;
        this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
          if(resp && resp.data !== null && resp.data !== {}) {
            this.createService.setCreatedGroup(resp.data[0]);
          }else {
            this.service.showMessage('error', resp['statusDescription']);
          }
          this.service.offSpinner();
        });
     } else {
        this.service.offSpinner();
        this.service.showMessage('error', data['statusDescription']);
      }
    });
  }
  }


  resetForm(){
    this.group = '',
    this.brandCode = null ,
    this.categoryCode = null,
    this.searchCategory = '',
    this.searchBrand = ''
  }

  downloadTemplate() {
    let csvData = Utility.convertToCSV([], ['category code', 'brand name', 'group name', 'grouped for','attribute_1', 'attribute_2', 'attribute_3', 'attribute_4', 'attribute_5', 'attribute_6', 'attribute_7', 'attribute_8', 'attribute_9', 'attribute_10']);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, 'Bulk-Group-Creation');
  }

  downloadUpdationTemplate(){
    let csvData = Utility.convertToCSV([],['current group name', 'grouped for', 'new group name', 'attribute_1', 'attribute_2', 'attribute_3', 'attribute_4', 'attribute_5', 'attribute_6', 'attribute_7', 'attribute_8', 'attribute_9', 'attribute_10']);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, 'Bulk-Group-Updation');
  }

  onFileChanged(event,updateFlag) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    let flag = Utility.fileValidation(this.file);
    if (!flag) {
      this.file = null;
      this.service.showMessage("error", "Please upload file having extensions .csv only.");
    } else if(this.file.size < 22) {
      this.service.showMessage("error", "Please upload file with proper data");
    } else {
      if(updateFlag==false){
        this.fileUpload();
      }else{
        this.bulkUpdateGroup();
      }
    }
  }

  fileUpload() {
    let url = params.old_prod_supplier + urls.bulkUploadGroup + '?userId=' + this.userEmail + '&countryCode=' + this.countryCode;
    const uploadData = new FormData();
    uploadData.append("file", this.file);    
    this.service.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this.service.offSpinner();
        if (data['status']) {
          this.service.showMessage('success', data['statusDescription']);
          this.file = null;
          this.fileName = null;
          this.createService.setGroups(data['data']);
        } else {
          if(data['data']){
            this.popupData = data['data'];
            this.openPopup(this.confirmDialog1);
            //this.service.showMessage('error', data['data']);
          }else{
            this.service.showMessage('error', data['statusDescription']);
          }
        }
      },
      (err) => {
        this.service.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  bulkUpdateGroup(){
    let url = params.old_prod_supplier + urls.bulkUpdateGroup +'?countryCode='+this.countryCode+'&userId='+this.userEmail;
    const uploadData = new FormData();
    uploadData.append("file",this.file);

    this.service.callRestful('POST',url,uploadData,null,null,null,true).subscribe(
      (response)=>{
        this.service.offSpinner();
        if(response['status']){
          this.service.showMessage('success', response['statusDescription']);
          this.file = null;
          this.fileName = null;
        }
        else {
          if(response['data']){
            this.popupData = response['data'];
            this.openPopup(this.confirmDialog1);
            //this.service.showMessage('error', data['data']);
          }else{
            this.service.showMessage('error', response['statusDescription']);
          }
        }
      },
      (err) => {
        this.service.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  clearStack(event) {
    event.target.value = null;
    this.file = null;
    this.fileName = null;
  }

  openPopup(template) {
   this.dataSource.data = this.popupData;
    const dialogRef = this.dialog.open(template, {
      width: "810px",
      height: "auro",
      panelClass: "addFieldClass",
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  selectGroupingMode(event: any): void {
    this.groupingFor = event.value;
    console.log('Selected Grouping Mode:', this.groupingFor);
    // Add your logic to handle the selected value
  }

}
