import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { GlobalStateService } from 'src/app/global-state.service';
import { CreateService } from '../../create.service';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material';
import { params } from 'src/app/pages/params';
import { urls } from 'src/app/pages/urls';
import { Utility } from 'src/app/pages/utility';
import { MatTableDataSource } from "@angular/material";
import { throttle } from 'lodash';

@Component({
  selector: 'app-mapped-attribute-msn',
  templateUrl: './mapped-attribute-msn.component.html',
  styleUrls: ['./mapped-attribute-msn.component.scss']
})
export class MappedAttributeMsnComponent implements OnInit {
  file: any;
  fileName: any;
  groups:any [];
  selectedGroup : any = {};
  attributesList: any = [];
  mappedMSNList: any = [];
  attributeKey: any;
  countryCode: any;
  userEmail: string;
  msnList: any[];
  removeGroupData: any;
  msnKey: any;
  isGroupSelected: boolean =false;
  failureMSNs = [];
  displayedColumns: string[] = [
    "idProduct",
    "categoryCode",
    "brandName",
    "failureReason"
  ];
  displayedColumnsForGroupUpdate: string[] = [
    "groupName",
    "categoryCode",
    "brandName",
    "attributes",
    "failureReason"
  ];
  selectedGroupMode:string;
  public dataSource = new MatTableDataSource(this.failureMSNs);
  @ViewChild('confirmDialog1') confirmDialog1: TemplateRef<any>;
  @ViewChild('confirmDialogGroupUpdate') confirmDialogGroupUpdate: TemplateRef<any>;
  MsnSuccessMsg: string;
  MsnSuccessDemapMsg: string;
  fileGroup: any;
  fileNameGroup: any;
  groupMSNList: any = [];
  @ViewChild('confirmDialogGroup') confirmDialogGroup: TemplateRef<any>;
  public dataSourceGroup = new MatTableDataSource(this.groupMSNList);
  displayedColumns1: string[] = [
    "categoryCode",
    "brandName",
    "groupName",
    "idProduct",
    "message",
    "type"
  ];
  editGroup:any;
  updatedGroupName:string;
  popupData: any = [];
  public dataSourceGroupUpdate = new MatTableDataSource(this.popupData);
  pageNo = 1;
  getSearchGroupsBody:any;
  isLoading:boolean;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  private throttledScrollHandler: () => void;

  constructor( private createService : CreateService ,public dialog: MatDialog, private service: DataService) { 
    this.throttledScrollHandler = throttle(this.loadMoreGroups.bind(this), 500);
  }

  ngOnInit() {
    this.countryCode = this.service.getSelectedCountryCode();
    this.userEmail = this.service.getEmail();
    this.getAllDefaultGroups();
    this.createService.attributeUpdated.subscribe(
        (lang) => {
          this.selectedGroup = this.createService.getGroup();
          this.selectGroup(this.selectedGroup);
        }
      );
      this.createService.msnUpdated.subscribe((lang) => {
          this.getMappedMSN();}
      );
      this.createService.clearGroupUpdated.subscribe((lang) => {
        this.attributesList = [];
        this.mappedMSNList = [];
        this.selectedGroup = null;
        this.isGroupSelected = false;
      });
  }

  getAllDefaultGroups(){
    let body = {
      "brandId":null,
      "groupName": null,
      "categoryCode": null,
      "groupedFor": null
    }
    this.getSearchGroupsBody=body;
    let url = params.old_prod_supplier + urls.getSearchGroups +'?pageNo=1&pageSize=10';
    this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp && resp.data !== null && resp.data !== {}) {
        this.groups = resp['data'];
        this.pageNo++;
        this.selectGroup(this.groups[0]);
      }else {
        this.service.showMessage('error', resp['statusDescription']);
      }
      this.service.offSpinner();
    });
  }

  getDefaultGroups(e){
    this.getAllDefaultGroups();
  }

  getAllGroups(body){
      this.service.onSpinner();
      this.groups = [];
      this.attributesList = [];
      this.mappedMSNList = [];
      this.msnList = [];
      this.createService.clearGroup();
      this.getSearchGroupsBody = body;
      this.pageNo = 1;
      let url = params.old_prod_supplier + urls.getSearchGroups+'?pageNo='+this.pageNo+'&pageSize=10';
      this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
        if(resp && resp.data !== null && resp.data !== {}) {
          this.groups = resp['data'];
          this.pageNo++;
          this.selectGroup( this.groups[0]);
        }else {
          this.service.showMessage('error', resp['statusDescription']);
        }
        this.service.offSpinner();
      });
  }

  selectGroup(group){
    this.attributesList = [];
    this.mappedMSNList = [];
    this.selectedGroup = group;
    this.isGroupSelected = true;
    this.attributesList= this.selectedGroup['attributeDetails'];
    this.createService.setGroup(this.selectedGroup);
    this.createService.attributeListView(this.attributesList,this.attributesList.length);
    if(this.attributesList.length > 0){this.getMappedMSN();}
    this.selectedGroupMode = this.selectedGroup.catalogGroupDetails.groupedFor

  }

   getMappedMSN(){
      let url = params.old_prod_supplier + urls.getMappedMSNByGroup + this.selectedGroup.catalogGroupDetails.groupId;
      this.service.callRestful("GET", url, null, null, null, null).subscribe(resp => {
      if(resp && resp.data !== null && resp.data !== {}) {
        this.mappedMSNList = resp.data;
        this.msnList = [...this.mappedMSNList]
      } else {
        //this.service.showMessage('error', resp['statusDescription']);
      }
      this.service.offSpinner();
    });
  }

  async updateGroup(){
    const url = params.old_prod_supplier + urls.createGroup;
    let body = {
      "groupId" : this.selectedGroup.catalogGroupDetails.groupId,
      "brandId" :this.selectedGroup.catalogGroupDetails.brandId,
      "attributeId" : await this.getAttributesList(this.attributesList),
      "categoryId": this.selectedGroup.catalogGroupDetails.categoryId,
      "countryCode": this.countryCode,
      "userId" : this.userEmail,
      "priority" : 1
    }
    this.service.callRestful('POST', url, body, null, null, null).subscribe(this.processResult.bind(this));
  }

  processResult(data: any){
    this.service.offSpinner();
    if (data['status']) {
      this.service.showMessage('success', data['statusDescription']);
    } else {
      this.service.showMessage('error', data['statusDescription']);
    }
  }

  getAttributesList(attributeList){
    let attrList = attributeList.map(item => {return item.attributeId});
    return attrList;
  }

  markAsParentMSN(productId){
    this.service.onSpinner();
    let url = params.old_prod_supplier + urls.updateParentMSN + '?groupId=' +this.selectedGroup.catalogGroupDetails.groupId+ '&idProduct=' + productId;
    this.service.callRestful("GET", url, null, null, null, null).subscribe(resp => {
      if(resp['status']) {
        this.service.showMessage('success', resp['statusDescription']);
        this.getMappedMSN();
      }else {
        this.service.offSpinner();
        this.service.showMessage('error', resp['statusDescription']);
      }
    });
  }

  processUnmappedMSN(productId){
    let url = params.old_prod_supplier + urls.unmappedMappedMSN;
    let body = {
      "groupId":this.selectedGroup.catalogGroupDetails.groupId,
      "productId":[productId]
    };
    this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
      if(resp['status']) {
        this.getMappedMSN();
        this.service.showMessage('success', resp['statusDescription']);
      }else {
        this.service.showMessage('error', resp['statusDescription']);
      }
      this.service.offSpinner();
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.attributesList, event.previousIndex, event.currentIndex);

    this.updateGroup();
  }

  onFileChanged(event,updateFlag) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    let flag = Utility.fileValidation(this.file);
    if (!flag) {
      this.file = null;
      this.service.showMessage("error", "Please upload file having extensions .csv only.");
    } else if(this.file.size < 22) {
      this.service.showMessage("error", "Please upload file with proper data");
    } else {
      if(updateFlag==false){
        this.fileUpload();
      }else{
        this.bulkUpdateGroup();
      }
    }
  }

  fileUpload() {
    let url = params.old_prod_supplier + urls.uploadMSNBulk;
    let mapProductsRequest= {
      "userId": this.service.getEmail(),
      "groupId": this.selectedGroup.catalogGroupDetails.groupId,
    }
    const uploadData = new FormData();
    uploadData.append('file', this.file);
    uploadData.append('mapProductsRequest', JSON.stringify(mapProductsRequest));
    this.service.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this.service.offSpinner();
        if (data['status']) {
          this.service.showMessage("success", "File uploaded successfully");
          this.file = null;
          this.fileName = null;
          this.createService.setMappedMSNList(data.data);
          let result = data['data'];
          this.processfailedMSN(result);
        }
        else {
          this.service.showMessage('error', data['statusDescription']);
        }
      },
      (err) => {
        this.service.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }
  
  processfailedMSN(result){
    this.failureMSNs = [];
    if(result['failedCount'] === 0){
      let failedMSNList =  result['mappedProductResponses'];
      this.processBulkMsnResponse(failedMSNList);
      this.openPopup(this.confirmDialog1);
    }else if(result['failedCount'] > 0){
      let failedMSNList =  result['mappedProductResponses'];
      this.failureMSNs = failedMSNList.filter(msn => msn.mappedStatus === false && msn.deMappedStatus === false);
      if(result['successCount'] > 0) {
        this.processBulkMsnResponse(failedMSNList);
      }
      this.openPopup(this.confirmDialog1);
    }
  }

  processBulkMsnResponse(failedMSNList){
    let successMsn = failedMSNList.filter(msn => msn.mappedStatus === true).map(msn =>  msn.idProduct);
    let successDemapMsn = failedMSNList.filter(msn => msn.deMappedStatus === true).map(msn =>  msn.idProduct);
    if(successMsn.length > 0){
    this.MsnSuccessMsg = successMsn.join(", ") + ' mapped successfully.';
    }
    if(successDemapMsn.length > 0){
      this.MsnSuccessDemapMsg = successDemapMsn.join(", ") + ' demapped successfully.';
    }
  }

  clearStack(event) {
    event.target.value = null;
    this.file = null;
    this.fileName = null;
  }

  deleteMappedMsn(template: TemplateRef<any>, msn, index) {
    this.msnKey = msn;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        this.service.offSpinner();
        this.mappedMSNList.splice(index,1);
        this.processUnmappedMSN(msn.idProduct);
      }
    });
  }

  deleteAttribute(template: TemplateRef<any>, attribute, index) {
    this.attributeKey = attribute.attributeName;
    const dialogRef = this.dialog.open(template, {
      width: "430px",
      height: "auto",
      panelClass: "addFieldClass",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.value) {
        this.attributesList.splice(index,1);
        this.createService.attributeListView(this.attributesList,this.attributesList.length);
        this.service.showMessage("success",this.attributeKey + " is deleted successfully");
        this.updateGroup();
      }
    });
  }

  removeGroup(template: TemplateRef<any>,group, index){
    this.removeGroupData = group;
    const dialogRef = this.dialog.open(template, {
        width: "430px",
        height: "auto",
        panelClass: "addFieldClass",
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result.value) {
            let url = params.old_prod_supplier + urls.removeGroup + this.removeGroupData.catalogGroupDetails.groupId;
            this.service.callRestful("DELETE", url, null, null, null, null).subscribe(resp => {
            if(resp['status']) {
                this.groups.splice(index,1);
                this.service.showMessage('success', resp['statusDescription']);
                this.removeGroupData = null;
                this.createService.clearGroup();
            }
            this.service.offSpinner();
          });
         }
      });  
    }

    openPopup(template) {
      this.dataSource.data = this.failureMSNs;
       const dialogRef = this.dialog.open(template, {
         width: "710px",
         height: "auro",
         panelClass: "addFieldClass",
       });
   
       dialogRef.afterClosed().subscribe((result) => {
       });
     }

     editGroupName(template: TemplateRef<any>,group,i){
      this.editGroup = group;
      const dialogRef = this.dialog.open(template, {
        width: "430px",
        height: "auto",
        panelClass: "addFieldClass",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.value) {
          let body ={
            "groupId":this.editGroup.catalogGroupDetails.groupId,
            "groupName":this.updatedGroupName,
            "groupedFor":this.editGroup.catalogGroupDetails.groupedFor.toUpperCase(),
            "userId": this.userEmail
          }
            let url = params.old_prod_supplier + urls.editGroupName;
            this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
            if(resp['status']) {
                this.service.showMessage('success', resp['statusDescription']);
                this.editGroup = null;
                this.updatedGroupName = null; 
                this.groups[i].catalogGroupDetails.groupName = resp.data.groupName;
                console.log(this.groups[i])
            }else{
              this.service.showMessage('error',resp.statusDescription)
            }
            this.service.offSpinner();
          });
         }
      });  
     }

     downloadTemplate() {
      let csvData = Utility.convertToCSV([],["category code","brand name","group name","grouped for","product id","parent","map"]);
      csvData = csvData.substring(5);
      Utility.downLoadFile(csvData, 'Bulk-Group-MSN-Creation');
    } 


    onFileChangedGroup(event) {
    this.fileGroup = event.target.files[0];
    this.fileNameGroup = this.fileGroup.name;
    let flag = Utility.fileValidation(this.fileGroup);
    if (!flag) {
      this.fileGroup = null;
      this.service.showMessage("error", "Please upload file having extensions .csv only.");
    } else if(this.fileGroup.size < 22) {
      this.service.showMessage("error", "Please upload file with proper data");
    } else {
      this.fileUploadGroup();
    }
  }

  fileUploadGroup() {
   let url = params.old_prod_supplier + urls.uploadGroupMSN + this.service.getEmail();
    const uploadData = new FormData();
    uploadData.append('file', this.fileGroup);
    this.service.callRestful("POST", url, uploadData, null, null, null, true).subscribe(
      (data) => {
        this.service.offSpinner();
        if (data['status']) {
          this.service.showMessage("success", "File uploaded successfully");
          this.fileGroup = null;
          this.fileNameGroup = null;
          //this.createService.setMappedMSNList(data.data);
          let result = data['data'];
          this.processfailedMSNGroup(result);
        }
        else {
          if(data['statusDescription']){
            this.service.showMessage('error', data['statusDescription']);
          }
        }
      },
      (err) => {
        this.service.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  processfailedMSNGroup(result){
    this.groupMSNList =  result['responseTypes'];
    this.openPopupGroup(this.confirmDialogGroup);
  }

  openPopupGroup(template) {
    this.dataSourceGroup.data = this.groupMSNList;
     const dialogRef = this.dialog.open(template, {
       width: "710px",
       height: "auro",
       panelClass: "addFieldClass",
     });
 
     dialogRef.afterClosed().subscribe((result) => {
      this.getMappedMSN();
     });
   }

  clearStackGroup(event) {
    event.target.value = null;
    this.fileGroup = null;
    this.fileNameGroup = null;
  }

  exportToCsv(){
    if (this.groupMSNList && this.groupMSNList.length) {
      let groupListDetails = [];
      for(let i=0; i<this.groupMSNList.length ; i++){
          let body = {
            categoryCode :  this.groupMSNList[i].categoryCode,
            brandName : this.groupMSNList[i].brandName,
            groupName : this.groupMSNList[i].groupName ? this.groupMSNList[i].groupName : 'N/A',
            idProduct : this.groupMSNList[i].idProduct,
            message: this.groupMSNList[i].message,
            type: this.groupMSNList[i].type,
          }
          groupListDetails.push(body);
      }
        
      const headers = ['Category Code', 'Brand Name', 'Group Name','Product Id', 'Message', 'Status'];
      const apiParams = ['categoryCode', 'brandName', 'groupName', 'idProduct', 'message', 'type'];
      const csvData = Utility.convertToCSV(groupListDetails, apiParams, headers);
      Utility.downLoadFile(csvData, `Groups MSN Report Download`);
    } else {
      this.service.showMessage('error', 'no data found to export');
    }
  }

  downloadUpdationTemplate(){
    let csvData = Utility.convertToCSV([],['current group name', 'grouped for', 'new group name', 'attribute_1', 'attribute_2', 'attribute_3', 'attribute_4', 'attribute_5', 'attribute_6', 'attribute_7', 'attribute_8', 'attribute_9', 'attribute_10']);
    csvData = csvData.substring(5);
    Utility.downLoadFile(csvData, 'Bulk-Group-Updation');
  }

  bulkUpdateGroup(){
    let url = params.old_prod_supplier + urls.bulkUpdateGroup +'?countryCode='+this.countryCode+'&userId='+this.userEmail;
    const uploadData = new FormData();
    uploadData.append("file",this.file);

    this.service.callRestful('POST',url,uploadData,null,null,null,true).subscribe(
      (response)=>{
        this.service.offSpinner();
        if(response['status']){
          this.service.showMessage('success', response['statusDescription']);
          this.getAllDefaultGroups();
          this.file = null;
          this.fileName = null;
        }
        else {
          if(response['data']){
            this.popupData = response['data'];
            this.openPopupGroupUpdate(this.confirmDialogGroupUpdate);
            //this.service.showMessage('error', data['data']);
          }else{
            this.service.showMessage('error', response['statusDescription']);
          }
        }
      },
      (err) => {
        this.service.showMessage("error", "Unable to proceed your request, Please try after some time");
      });
  }

  openPopupGroupUpdate(template) {
    this.dataSourceGroupUpdate.data = this.popupData;
     const dialogRef = this.dialog.open(template, {
       width: "810px",
       height: "auro",
       panelClass: "addFieldClass",
     });
 
     dialogRef.afterClosed().subscribe((result) => {
     });
   }

   onScroll(event: any) {
    const element = event.target;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 10) {
      this.loadMoreGroups(); // Load more data when scrolled to the bottom
    }
  }

  loadMoreGroups(){
    if (this.isLoading) return; // Prevent multiple calls

    this.isLoading = true;
    let body = this.getSearchGroupsBody;
    let url = params.old_prod_supplier + urls.getSearchGroups+'?pageNo='+this.pageNo+'&pageSize=10';
      this.service.callRestful("POST", url, body, null, null, null).subscribe(resp => {
        if(resp && resp.data !== null && resp.data !== {}) {
          this.groups = [...this.groups, ...resp['data']];
          this.pageNo++; // Increment page for next call
        this.isLoading = false;
          this.selectGroup( this.groups[0]);
        }else {
          this.service.showMessage('error', resp['statusDescription']);
          this.isLoading=false;
        }
        this.service.offSpinner();
      });
  }

}
